import { TrackingService } from "./trackingService";
import { IssueService } from "./issueService";
import { ProjectService } from "./projectService";
import { DepartmentService } from "./departmentService";
import { EmployeeService } from "./employeeService";
import { axiosApi } from "../configs/apiConfig";
import { UserService } from "./userService";
import { ProjectTypeService } from "./projectTypeService";
import { WorkTypeService } from "./workTypeService";
import { CustomerService } from "./customerService";
import { PartnerService } from "./partnerService";
import { PermissionService } from "./permissionService";
import { AccountService } from "./accountService";
import { CurrencyService } from "./currencyService";
import { WorkEntryService } from "./workEntryService";
import { ProjectDraftService } from "./projectDraftService";
import { ReportService } from "./reportService";
import { HistoryService } from "./HistoryService";
import { issueDraftService } from "./issueDraftService";
import { AdministrationTimeService } from "./administrationTimeService";
import { ReportConfigurationService } from "./reportConfigurationService";
import { SalaryService } from "./salaryService";
import { CatalogIssueService } from "./catalogIssueService";

export const AppDependencies = {
  Axios: axiosApi,
  TrackingService: new TrackingService(),
  IssueService: new IssueService(),
  IssueDraftsService: new issueDraftService(),
  ProjectService: new ProjectService(),
  ProjectDraftService: new ProjectDraftService(),
  DepartmentService: new DepartmentService(),
  EmployeeService: new EmployeeService(),
  UserService: new UserService(),
  ProjectTypeService: new ProjectTypeService(),
  WorkTypeService: new WorkTypeService(),
  CustomerService: new CustomerService(),
  PartnerService: new PartnerService(),
  PermissionService: new PermissionService(),
  AccountService: new AccountService(),
  CurrencyService: new CurrencyService(),
  WorkEntryService: new WorkEntryService(),
  ReportService: new ReportService(),
  HistoryService: new HistoryService(),
  AdministrationTimeService: new AdministrationTimeService(),
  ReportConfigurationService: new ReportConfigurationService(),
  SalaryService: new SalaryService(),
  CatalogIssueService: new CatalogIssueService(),
};
