// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import {localization} from "../lang/localizationSlice";
import {account} from "./slices/accountSlice";
import {timeSheet} from "./slices/timeSheetSlice";
import {homeReducer} from "./slices/homeSlice";
import {uiReducer} from "./slices/uiSlice";

const rootReducer = { navbar, layout, localization, account, timeSheet, homeReducer, uiReducer}

export default rootReducer
