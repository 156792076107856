import axios from "axios";
import {axiosApi, currentApi} from "../configs/apiConfig";
import {toast} from "react-hot-toast";
import {ICreatePartnerDto} from "../dtos/partners/ICreatePartnerDto";
import {IPartnerDto} from "../dtos/partners/IPartnerDto";
import {AppDependencies} from "./appDependencies";

export class PartnerService {
    async GetAll(searchText?: string) {
        let url = `/partners`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async Create(item: ICreatePartnerDto) {
        try {
            const response = await AppDependencies.Axios.post(`/partners`, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Created successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async Update(item: IPartnerDto) {
        try {
            const response = await AppDependencies.Axios.put(`/partners`, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async Delete(id: string) {
        try {
            const response = await AppDependencies.Axios.delete(`/partners/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }
}
