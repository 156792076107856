import axios from "axios";
import {axiosApi, currentApi} from "../configs/apiConfig";
import {toast} from "react-hot-toast";
import {ICreateEmployeeDto} from "../dtos/employees/ICreateEmployeeDto";
import {IEmployeeDto} from "../dtos/employees/IEmployeeDto";
import {IEmployeeListItemDto} from "../models/IEmployeeListItemDto";

export class EmployeeService {
    async GetAll(searchText?: string): Promise<IEmployeeDto[]> {
        let url = `/employees`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await axiosApi.get(url);
        return response.data;
    }

    async GetList(searchText?: string): Promise<IEmployeeListItemDto[]> {
        let url = `/employees/list`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await axiosApi.get(url);
        console.log(response)
        return response.data;
    }    

    async GetOne(employeeId: string): Promise<IEmployeeDto> {
        const url = `/employees/one/${employeeId}`;
        const response = await axiosApi.get(url);
        console.log(response)
        return response.data;
    }

    async UpdateNotificationDate(date: Date): Promise<number> {
        const url = `/employees/${date}`;
        const response = await axiosApi.put(url);
        
        return response.status;
    }

    async GetAllByDepartment(departmentId: string): Promise<IEmployeeDto[]> {
        const url = `/employees/by-department/${departmentId}`;
        const response = await axiosApi.get(url);
        return response.data;
    }

    async Create(item: ICreateEmployeeDto) {
        try {
            const response = await axiosApi.post(`/employees`, item, {});
            if (process.env.MODE === "debug") {
            }

            if (response.status === 200) {
                toast.success("Created successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async Update(item: IEmployeeDto) {
        try {
            const response = await axiosApi.put(`/employees`, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async Delete(id: string) {
        try {
            const response = await axiosApi.delete(`/employees/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }
}
