import {IAdministrationTimeDto, IDate} from "../dtos/administrationTime/IAdministrationTimeDto";
import {AppDependencies} from "./appDependencies";
import {toast} from "react-hot-toast";
import {ICreateAdministrationTimeDto} from "../dtos/administrationTime/ICreateAdministrationTimeDto";
import { TimesheetReportRequestDto } from "../dtos/reports/TimesheetReportRequestDto";

export class AdministrationTimeService {
    async GetAll(searchText?: string): Promise<IAdministrationTimeDto[]> {
        let url = `/AdministrationTime`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async Create(item: ICreateAdministrationTimeDto): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.post("/AdministrationTime", item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status !== 200) {
                throw `requestError. status code ${response.status}`;
            }

            toast.success("Created successfully!");
            return true;

        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async GetOne(administrationTimeId: string): Promise<IAdministrationTimeDto> {
        const url = `/AdministrationTime/one/${administrationTimeId}`;
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async Update(item: ICreateAdministrationTimeDto): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.put("/AdministrationTime", item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async Delete(id: string): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.delete(`/AdministrationTime/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }

    async GetByType(id?: string): Promise<IAdministrationTimeDto[]> {
        const url = `/AdministrationTime/byType/${id}`;
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async GetAdministrationTime(): Promise<IDate[]> {
        const url = "/AdministrationTime/all/calendar";
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }
    async GetAdministrationTimeReport(startDate:Date, endDate:Date): Promise<IDate[]> {
        try {
            const dto: TimesheetReportRequestDto = {
                startDate,
                endDate,
              };
            const response = await AppDependencies.Axios.post("/AdministrationTime/month/report", dto, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status !== 200) {
                throw `requestError. status code ${response.status}`;
            }
            return response.data;

        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Failure!");
        }
        return [];
    }
}
