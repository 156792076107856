import axios from "axios";

const devApi = "https://localhost:7173/api";
const prodApi = "https://timesheet-api.soft-unity.com/api";
const testApi = "https://timesheet-test-api.soft-unity.com/api";
const myTestApi = "https://timesheet-test.ecoit.ge/api";
const nodeApi_prod = "https://timesheet.ecoit.ge/api";
const nodeApi = process.env.REACT_APP_LOCAL_API;
export const currentApi = nodeApi_prod;

export const axiosApi = axios.create({
  baseURL: currentApi,
});
