import axios from "axios";
import {currentApi} from "../configs/apiConfig";
import {toast} from "react-hot-toast";
import {ICreatePositionDto} from "../dtos/positions/ICreatePositionDto";
import {IPositionDto} from "../dtos/positions/IPositionDto";
import {ICreateUserDto} from "../dtos/users/ICreateUserDto";
import {IUpdateUserDto} from "../dtos/users/IUpdateUserDto";
import {AppDependencies} from "./appDependencies";
import {IApplicationUserDto} from "../dtos/users/IApplicationUserDto";
import {IUserListItemDto} from "../dtos/users/IUserListItemDto";

export class UserService {
    async GetAll(searchText?: string) {
        let url = `${currentApi}/users`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }
        
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async GetList(searchText?: string): Promise<IUserListItemDto[]> {
        let url = `${currentApi}/users/list`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }
        const response = await AppDependencies.Axios.get(url);
        console.log(response)
        return response.data;
    }    
    
    async Create(item: ICreateUserDto) {
        try {
            const response = await AppDependencies.Axios.post(`${currentApi}/users`, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Created successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async Update(item: IUpdateUserDto) {
        try {
            const response = await AppDependencies.Axios.put(`${currentApi}/users`, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async Delete(id: string) {
        try {
            const response = await AppDependencies.Axios.delete(`${currentApi}/users/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }

    async GetOne(userId: string): Promise<IApplicationUserDto> {
        const url = `/users/one/${userId}`;
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }
}
